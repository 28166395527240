import React from 'react';
import Scrollbars from '../Scrollbars';
import ChatMessage from '../ChatMessage';
import ChatEvent from '../ChatEvent';
import ChatCustomerInput from '../ChatCustomerInput';

class ChatContents extends React.PureComponent {
  constructor(props) {
    super(props);
    
    this.state = {
      chatsLength: 0,
      chatWindowHeight: parseInt(window.innerHeight * 0.47),
    }
    this.resizeChatWindow = this.resizeChatWindow.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeChatWindow);
    
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeChatWindow);
  };

  resizeChatWindow() {
    this.setState({
      chatWindowHeight: parseInt(window.innerHeight * 0.47)
    })
  }

  handleUpdate(){
    if (this.state.chatsLength < this.props.chatContentList.length) {
      this.scrollBar.scrollToBottom()
      this.setState({ chatsLength: this.props.chatContentList.length })
    }
  };

  render() {
    return (
      <div>
        <Scrollbars
          style={{ width: "100%", height: this.state.chatWindowHeight }}
          ref={ e => this.scrollBar = e }
          onUpdate={ this.handleUpdate.bind(this) }
        >
          {this.props.chatContentList.map((chatContent) => (
            chatContent.EventType === "MESSAGE" ? 
            <ChatMessage 
              key={chatContent.EventId} 
              chatContent={chatContent}
              agent={this.props.agent}
              languageCode={this.props.languageCode}
              showOriginal={this.props.showOriginal}
              showTranslated={this.props.showTranslated}
            /> 
            : 
            <ChatEvent 
              key={chatContent.EventId}
              chatContent={chatContent}
              agent={this.props.agent}
              languageCode={this.props.languageCode}
            />
          ))}
        </Scrollbars>
        <ChatCustomerInput 
          languageCode={this.props.languageCode}
          onKeyPress={this.props.onKeyPress}
          isSending={this.props.isSending}
          currentMessage={this.props.currentMessage}
          handleDoNotTranslateChange={this.props.handleDoNotTranslateChange}
          handleDoNotTranslateClick={this.props.handleDoNotTranslateClick}
          handleShowOriginalChange={this.props.handleShowOriginalChange}
          handleShowTranslatedChange={this.props.handleShowTranslatedChange}
          handleChatMessageChange={this.props.handleChatMessageChange}
        />
      </div>
    )
  }
};

export default ChatContents;