import React from 'react';
import Container from 'aws-northstar/layouts/Container';
import LoadingIndicator from 'aws-northstar/components/LoadingIndicator';
import TranscriptContents from '../TranscriptContents';

class TranscriptWindow extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <Container headingVariant="h2"
        title="Chat Transcript"
        subtitle={ 
          !this.props.isContactIdSet ? 
          "No chat transcript to show." : 
          "Contact ID: " + this.props.contactId
        }
      >
        { this.props.isLoading ? 
          <LoadingIndicator size={10} label="Loading chat transcript ..."/> :
          ""
        }
        { !this.props.isLoading && this.props.isContactIdSet ? 
          <TranscriptContents
            chatContentList={this.props.chatContentList}
          /> :
          ""
        }
      </Container>
    );
  }
}

export default TranscriptWindow;