import Tabs from 'aws-northstar/components/Tabs';
import AppHeader from './components/Header';
import ChatAppContainer from './components/ChatAppContainer';
import TranscriptAppContainer from './components/TranscriptAppContainer';

function App() {
  const tabs = [
    {
      label: "Chat Window",
      id: "chat",
      content: <ChatAppContainer />
    },
    {
      label: "Transcript Viewer",
      id: "transcript",
      content: <TranscriptAppContainer />
    }
  ]

  return (
    <div>
      <AppHeader />
      <Tabs tabs={tabs} paddingContentArea={false} />
    </div>
  );
}

export default App;
