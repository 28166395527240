import React from 'react';
import Button from 'aws-northstar/components/Button';
import Checkbox from 'aws-northstar/components/Checkbox';
import Grid from 'aws-northstar/layouts/Grid';
import Textarea from 'aws-northstar/components/Textarea';
import {
  CUSTOMER_INPUT_PLACEHOLDER,
  SHOW_ORIGINAL_CHECKBOX_LABEL,
  SHOW_ORIGINAL_CHECKBOX_DESCRIPTION,
  SHOW_TRANSLATION_CHECKBOX_LABEL,
  SHOW_TRANSLATION_CHECKBOX_DESCRIPTION,
  SEND_WITHOUT_TRANSLATE_BUTTON
} from '../../utilities/constants';

const ChatCustomerInput = ({
  languageCode, 
  onKeyPress,
  isSending,
  currentMessage,
  handleDoNotTranslateClick,
  handleShowOriginalChange,
  handleShowTranslatedChange,
  handleChatMessageChange
}) => {

  return (
    <div style={{ height: parseInt(window.innerHeight * 0.13), marginTop: "8px"}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Textarea 
            placeholder={CUSTOMER_INPUT_PLACEHOLDER[languageCode]}
            rows="5"
            value={currentMessage}
            onKeyDown={onKeyPress}
            onChange={handleChatMessageChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Checkbox 
            description={SHOW_ORIGINAL_CHECKBOX_DESCRIPTION[languageCode]}
            onChange={handleShowOriginalChange}
          >
            {SHOW_ORIGINAL_CHECKBOX_LABEL[languageCode]}
          </Checkbox>
        </Grid>
        <Grid item xs={4}>
          <Checkbox 
            description={SHOW_TRANSLATION_CHECKBOX_DESCRIPTION[languageCode]}
            onChange={handleShowTranslatedChange}
          >
            {SHOW_TRANSLATION_CHECKBOX_LABEL[languageCode]}
          </Checkbox>
        </Grid>
        <Grid item xs={4} style={{ display: "flex", alignItems: "center"}}>
          <div style={{ margin: "0 0 0 auto" }}>
            <Button 
              variant="primary"
              onClick={() => handleDoNotTranslateClick()}
              disabled={isSending || currentMessage == ""}
            >
              {SEND_WITHOUT_TRANSLATE_BUTTON[languageCode]}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ChatCustomerInput;