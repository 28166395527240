import React from 'react';
import Container from 'aws-northstar/layouts/Container';
import LoadingIndicator from 'aws-northstar/components/LoadingIndicator';
import ChatContents from '../ChatContents';
import { 
  CHAT_WINDOW, 
  CHAT_WINDOW_DESCRIPTION,
  CHAT_LOADING_TEXT
} from '../../utilities/constants';

class ChatWindow extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <Container headingVariant="h2"
        title={CHAT_WINDOW[this.props.languageCode]}
        subtitle={ 
          this.props.contactId == null ? 
          CHAT_WINDOW_DESCRIPTION[this.props.languageCode] : 
          "Contact ID: " + this.props.contactId
        }
      >
        { this.props.isLoading ? 
          <LoadingIndicator size={10} label={CHAT_LOADING_TEXT[this.props.languageCode]}/> :
          this.props.contactId == null ?
          "" :
          <ChatContents 
            chatContentList={this.props.chatContentList}
            agent={this.props.agent}
            isSending={this.props.isSending}
            currentMessage={this.props.currentMessage}
            languageCode={this.props.languageCode}
            showOriginal={this.props.showOriginal}
            showTranslated={this.props.showTranslated}
            onKeyPress={this.props.onKeyPress}
            handleDoNotTranslateChange={this.props.handleDoNotTranslateChange}            
            handleDoNotTranslateClick={this.props.handleDoNotTranslateClick}
            handleShowOriginalChange={this.props.handleShowOriginalChange}
            handleShowTranslatedChange={this.props.handleShowTranslatedChange}
            handleChatMessageChange={this.props.handleChatMessageChange}
          />
        }
      </Container>
    );
  }
}

export default ChatWindow;
