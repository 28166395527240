import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  message: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "98%",
    marginLeft: "10px",
  },
  customerBubbleContainer: {
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex-end",
  },  
  otherBubbleContainer: {
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex",
  },
  customerBubble: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    marginTop: "5px",
    marginBottom: "5px",
    margin: "1px 0px",
    background: "#ec7211",
    padding: "10px 15px",
    maxWidth: "80%",
    color: "white",
  },
  customerBubblePending: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    marginTop: "5px",
    marginBottom: "5px",
    margin: "1px 0px",
    background: "#f3aa70",
    padding: "10px 15px",
    maxWidth: "80%",
    color: "white",
  },
  otherBubble: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    marginTop: "5px",
    marginBottom: "5px",
    margin: "1px 0px",
    background: "#f4f4f8",
    padding: "10px 15px",
    maxWidth: "80%",
  },
  customerName: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "flex-end",
  },
  otherName: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "flex",
  },
  failureContainer: {
    fontSize: "13px",
    color: "red",
    lineHeight: "350%",
    marginRight: "3px"
  },
  chatText: {
    whiteSpace: "pre-wrap"
  }
}));

const TranscriptMessage = ({
  chatContent
}) => {
  const classes = useStyles();

  return (
    <div>
      { chatContent.IsBeginning ? 
        <div
          className={chatContent.ParticipantRole === "CUSTOMER" ? classes.customerName : classes.otherName}
        >
          {chatContent.ParticipantRole === "CUSTOMER" ? "CUSTOMER" : 
            chatContent.ParticipantRole === "AGENT" ? "AGENT" : "SYSTEM"
          }
        </div> 
        :
        ""
      }
      <div 
        className={chatContent.ParticipantRole === "CUSTOMER" ? classes.customerBubbleContainer : classes.otherBubbleContainer}
      >
        {
          <div className={chatContent.ParticipantRole === "CUSTOMER" ? classes.customerBubble : classes.otherBubble}>
            {chatContent.SourceLanguageText === chatContent.TargetLanguageText ? 
              <div className={classes.chatText}>
                <strong>(No Translation)</strong><br/>
                {chatContent.SourceLanguageText}
              </div> 
              : 
              <div className={classes.chatText}>
                <strong>Original:</strong><br/>
                {chatContent.SourceLanguageText}
                <hr/>
                <strong>Translated:</strong><br/>
                {chatContent.TargetLanguageText}
              </div>
            }
          </div>
        }        
      </div>
    </div>
  )
}

export default TranscriptMessage;