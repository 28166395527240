import React from 'react';
import Scrollbars from '../Scrollbars';
import TranscriptMessage from '../TranscriptMessage';

class TranscriptContents extends React.PureComponent {
  constructor(props) {
    super(props);
    
    this.state = {
      chatsLength: 0,
      chatWindowHeight: parseInt(window.innerHeight * 0.60),
    }
    this.resizeChatWindow = this.resizeChatWindow.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeChatWindow);
    
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeChatWindow);
  };

  resizeChatWindow() {
    this.setState({
      chatWindowHeight: parseInt(window.innerHeight * 0.47)
    })
  }

  handleUpdate(){
    if (this.state.chatsLength < this.props.chatContentList.length) {
      this.scrollBar.scrollToBottom()
      this.setState({ chatsLength: this.props.chatContentList.length })
    }
  };

  render() {
    return (
      <div>
        <Scrollbars
          style={{ width: "100%", height: this.state.chatWindowHeight }}
          ref={ e => this.scrollBar = e }
          onUpdate={ this.handleUpdate.bind(this) }
        >
          {this.props.chatContentList.map((chatContent) => (
            <TranscriptMessage 
              key={chatContent.EventId}
              chatContent={chatContent}
            /> 
          ))}
        </Scrollbars>
      </div>
    )
  }
};

export default TranscriptContents;