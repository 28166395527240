import React from 'react';
import axios from 'axios';
import Container from 'aws-northstar/layouts/Container';
import ChatUtility from '../ChatUtility';
import ChatWindow from '../ChatWindow';
import { 
  getChatContent,
  buildCustomerChatContentObject
} from '../../utilities/helperFunctions';

class ChatAppContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSending: false,
      isEnding: false,
      languageCode: 'en',
      agent: 'agent_1',
      contactId: null,
      connectionToken: null,
      ws: null,
      isListenerAdded: false,
      chatContentList: [],
      currentMessage: "",
      showOriginal: false,
      showTranslated: false,
    }

    this.handleAgentChange = this.handleAgentChange.bind(this);
    this.handleLanguageCodeChange = this.handleLanguageCodeChange.bind(this);
    this.handleChatMessageChange = this.handleChatMessageChange.bind(this);
    this.handleShowOriginalChange = this.handleShowOriginalChange.bind(this);
    this.handleShowTranslatedChange = this.handleShowTranslatedChange.bind(this);
    this.handleStartChatClick = this.handleStartChatClick.bind(this);
    this.handleDisconnectChatClick = this.handleDisconnectChatClick.bind(this);
    this.handleDoNotTranslateClick = this.handleDoNotTranslateClick.bind(this);
    this.translateAgentMessage = this.translateAgentMessage.bind(this);
    this.messageListener = this.messageListener.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }
  
  startChat() {
    axios.get(
      process.env.REACT_APP_START_CHAT_API +
      "?SourceLanguageCode=" + this.state.languageCode +
      "&Agent=" + this.state.agent
    )
    .then((response) => {
      const ws = new WebSocket(response.data.WebsocketUrl);
      this.setState({
        contactId: response.data.ContactId,
        connectionToken: response.data.ConnectionToken,
        ws: ws,
      });
    })
    .then(() => {
      const firstMessage = {
        topic: "aws/subscribe",
        content: {
          topics: ["aws/chat"]
        }
      }
      this.state.ws.addEventListener("open", () => {
        this.state.ws.send(JSON.stringify(firstMessage))
      })
    })
    .then(() => {
      this.state.ws.addEventListener("message", this.messageListener);
      this.setState({
          isListenerAdded: true
      });
    })
    .then(() => {
      this.setState({
        isLoading: false
      })
    });
  }

  sendMessage(message, index, isTranslate) {
    if (this.state.ws != null) {
      axios.post(
        process.env.REACT_APP_SEND_MESSAGE_API + 
        "?ConnectionToken=" + this.state.connectionToken + 
        "&ContactId=" + this.state.contactId +
        "&SourceLanguageCode=" + this.state.languageCode + 
        "&Translate=" + isTranslate,
        message
      )
      .then((response) => {
        let tmpChatContentList = this.state.chatContentList.concat();
        tmpChatContentList[index].Status = "COMPLETED";
        tmpChatContentList[index].TranslatedContent = response.data.TranslatedText;
        this.setState({
          isSending: false,
          chatContentList: tmpChatContentList
        });
      });
    }
  }

  translateAgentMessage(chatData) {
    axios.post(
      process.env.REACT_APP_TRANSLATE_AGENT_MESSAGE_API + 
      "?ContactId=" + this.state.contactId +
      "&TargetLanguageCode=" + this.state.languageCode,
      chatData
    )
    .then((response) => {
      let tmpChatContentList = this.state.chatContentList.concat();
      if (response.data.EventType == "MESSAGE") {
        if (response.data.ParticipantRole != "CUSTOMER") {
          const chatContent = getChatContent(tmpChatContentList, response.data);
          tmpChatContentList.push(chatContent);
          this.setState({
            chatContentList: tmpChatContentList
          })
        }
      } else if (response.data.EventType == "JOINED" || response.data.EventType == "LEFT" || response.data.EventType == "ENDED") {
        tmpChatContentList.push(response.data);
        this.setState({
          chatContentList: tmpChatContentList
        })
      }
    });
  }

  disconnectChat() {
    axios.post(
      process.env.REACT_APP_DISCONNECT_CHAT_API + 
      "?ContactId=" + this.state.contactId + 
      "&ConnectionToken=" + this.state.connectionToken
    )
    .then((response) => {
      this.setState({
        contactId: null,
        connectionToken: null,
        ws: null,
        isListenerAdded: false,
        chatContentList: [],
        isLoading: false,
        isSending: false,
        isEnding: false,
        showOriginal: false,
        noTranslate: false
      })
    });
  }

  async handleStartChatClick() {
    this.setState({
      isLoading: true
    });
    this.startChat();
  }

  async handleDisconnectChatClick() {
    this.setState({
      isEnding: true
    });
    this.disconnectChat()
  }

  handleChatMessageChange(event) {
    this.setState({
      currentMessage: event.target.value
    });
  }
  
  async keyPress(event) {
    if (event.key === "Enter" && !event.shiftKey && event.keyCode === 13 && event.target.value !== "") {
      event.preventDefault();

      let tmpChatContentList = this.state.chatContentList.concat();
      const chatContent = getChatContent(
        tmpChatContentList, 
        buildCustomerChatContentObject(this.state.currentMessage)
      );
      tmpChatContentList.push(chatContent);
      const latestChatContentIndex = tmpChatContentList.length - 1;

      let isTranslate = "YES";
      if (this.state.languageCode === "en") {
        isTranslate = "NO"
      }
      this.sendMessage(this.state.currentMessage, latestChatContentIndex, isTranslate);
      this.setState({
        isSending: true,
        currentMessage: "",
        chatContentList: tmpChatContentList
      });
    }
  }

  async handleDoNotTranslateClick() {
    if (this.state.currentMessage !== "") {
      let tmpChatContentList = this.state.chatContentList.concat();
      const chatContent = getChatContent(
        tmpChatContentList,
        buildCustomerChatContentObject(this.state.currentMessage)
      );
      tmpChatContentList.push(chatContent);
      const latestChatContentIndex = tmpChatContentList.length - 1;
      this.sendMessage(this.state.currentMessage, latestChatContentIndex, "NO");
      this.setState({
        isSending: true,
        currentMessage: "",
        chatContentList: tmpChatContentList,
      });
    }
  }

  handleLanguageCodeChange(event) {
    this.setState({
      languageCode: event.target.value
    });
  }

  handleAgentChange(event) {
    this.setState({
      agent: event.target.value
    });
  }

  handleShowOriginalChange(event) {
    this.setState({
      showOriginal: event.target.checked
    })
  }

  handleShowTranslatedChange(event) {
    this.setState({
      showTranslated: event.target.checked
    })
  }

  messageListener(event) {
    this.translateAgentMessage(event.data);
  }

  render() {
    return (
      <Container>
        <ChatUtility 
          agent={this.state.agent}
          languageCode={this.state.languageCode}
          isLoading={this.state.isLoading}
          contactId={this.state.contactId}
          isEnding={this.state.isEnding}
          handleAgentChange={this.handleAgentChange}
          handleLanguageCodeChange={this.handleLanguageCodeChange}
          handleStartChatClick={this.handleStartChatClick}
          handleDisconnectChatClick={this.handleDisconnectChatClick}
        />
        <ChatWindow
          chatContentList={this.state.chatContentList}
          isLoading={this.state.isLoading}
          isSending={this.state.isSending}
          currentMessage={this.state.currentMessage}
          isEnding={this.state.isEnding}
          agent={this.state.agent}
          languageCode={this.state.languageCode}
          contactId={this.state.contactId}
          showOriginal={this.state.showOriginal}
          showTranslated={this.state.showTranslated}
          onKeyPress={this.keyPress}
          handleDoNotTranslateClick={this.handleDoNotTranslateClick}
          handleShowOriginalChange={this.handleShowOriginalChange}
          handleShowTranslatedChange={this.handleShowTranslatedChange}
          handleChatMessageChange={this.handleChatMessageChange}
        />
      </Container>
    );
  }
}

export default ChatAppContainer