import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Form from 'aws-northstar/components/Form';
import FormSection from 'aws-northstar/components/FormSection';
import FormField from 'aws-northstar/components/FormField';
import Grid from 'aws-northstar/layouts/Grid';
import Input from 'aws-northstar/components/Input';
import Button from 'aws-northstar/components/Button';

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  container: {
    width: "100%"
  },
  selector: {
    width: "30%",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  selectorChild: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    
  }
}));

const TranscriptUtility = ({
  isLoading,
  contactId,
  handleContactIdChange,
  handleGetChatTranscriptClick
}) => {
  
  const classes = useStyles();
  return (
    <div>
      <Form
        actions = {
          <Button 
            variant="primary" 
            onClick={() => handleGetChatTranscriptClick()} 
            disabled={isLoading}
          >
            Get Chat Transcript
          </Button>
        }
        className={classes.form}
      >
        <FormSection header="Chat Transcript Setting">
          <Grid container spacing={0} className={classes.container}>
            <Grid item xs={5} className={classes.selector}>
              <FormField 
                label="Contact ID"
                hintText="You can retrieve chat transcript with Contact ID specified here."
              >
                <Input 
                  type="text"
                  value={contactId}
                  controlId="contactId"
                  onChange={(value) => handleContactIdChange(value)}
                />
              </FormField>
            </Grid>
          </Grid>
        </FormSection>
      </Form>
    </div>
  )
}

export default TranscriptUtility;