export const START_CHAT_BUTTON= {
  "en": "Start Chat",
  "ja": "チャットを開始",
  "zh": "开始聊天",
  "zh-TW": "開始聊天",
  "ko": "채팅 시작",
  "fr": "Commencer le chat",
  "es": "Iniciar chat",
  "pt": "Iniciar bate-papo",
  "ru": "Начать чат",
  "tr": "Sohbet Başlat",
  "de": "Chat starten"
}

export const CHAT_SETTINGS= {
  "en": "Chat Settings",
  "ja": "チャットの設定",
  "zh": "聊天设置",
  "zh-TW": "聊天設置",
  "ko": "채팅 설정",
  "fr": "Paramètres du chat",
  "es": "Configuración de chat",
  "pt": "Configurações de bate-papo",
  "ru": "Настройки чата",
  "tr": "Sohbet Ayarları",
  "de": "Chat-Einstellungen"
}

export const AGENT_LABEL= {
  "en": "Agent",
  "ja": "エージェント",
  "zh": "代理人",
  "zh-TW": "代理",
  "ko": "에이전트",
  "fr": "Agent",
  "es": "Agente",
  "pt": "Agente",
  "ru": "Агент",
  "tr": "Ajan",
  "de": "Agent"
}

export const AGENT_HINT_TEXT= {
  "en": "Select an agent you will be chatting with.",
  "ja": "チャットをするエージェントを選択してください。",
  "zh": "选择要与之聊天的座席。",
  "zh-TW": "選擇要與之聊天的座席。",
  "ko": "채팅할 상담원을 선택합니다.",
  "fr": "Sélectionnez un agent avec lequel vous allez discuter.",
  "es": "Seleccione un agente con el que charlará.",
  "pt": "Selecione um agente com quem você conversará.",
  "ru": "Выберите агента, с которым вы будете общаться.",
  "tr": "Sohbet edeceğiniz bir temsilci seçin.",
  "de": "Wählen Sie einen Agenten aus, mit dem Sie chatten möchten."
}

export const LANGUAGE_LABEL= {
  "en": "Language",
  "ja": "言語",
  "zh": "语言",
  "zh-TW": "語言",
  "ko": "언어",
  "fr": "Langue",
  "es": "Idioma",
  "pt": "Idioma",
  "ru": "Язык",
  "tr": "Dil",
  "de": "Sprache"
}

export const LANGUAGE_HINT_TEXT= {
  "en": "Select your preferrable language.",
  "ja": "お好みの言語を選択してください。",
  "zh": "选择您的首选语言。",
  "zh-TW": "選擇您的首選語言。",
  "ko": "원하는 언어를 선택합니다.",
  "fr": "Sélectionnez votre langue préférée.",
  "es": "Selecciona el idioma que prefieras.",
  "pt": "Selecione o idioma de sua preferência.",
  "ru": "Выберите предпочитаемый язык.",
  "tr": "Tercih ettiğiniz dili seçin.",
  "de": "Wählen Sie Ihre bevorzugte Sprache."
}

export const CHAT_WINDOW= {
  "en": "Current Chat",
  "ja": "現在のチャット",
  "zh": "当前聊天",
  "zh-TW": "當前聊天",
  "ko": "현재 채팅",
  "fr": "Conversation actuelle",
  "es": "Chat actual",
  "pt": "Chat atual",
  "ru": "Текущий чат",
  "tr": "Geçerli Sohbet",
  "de": "Aktueller Chat"
}

export const CHAT_WINDOW_DESCRIPTION= {
  "en": "There is no chat to display. You can start chat with an agent.",
  "ja": "表示するチャットはありません。エージェントとチャットを開始できます。",
  "zh": "没有要显示的聊天记录。您可以开始与座席聊天。",
  "zh-TW": "沒有可顯示的聊天。您可以開始與代理聊天。",
  "ko": "표시할 채팅이 없습니다.상담원과 채팅을 시작할 수 있습니다.",
  "fr": "Il n'y a aucun chat à afficher. Vous pouvez démarrer un chat avec un agent.",
  "es": "No hay ningún chat que mostrar. Puedes empezar a chatear con un agente.",
  "pt": "Não há bate-papo para exibir. Você pode começar a conversar com um agente.",
  "ru": "Нет чата для отображения. Вы можете начать чат с агентом.",
  "tr": "Görüntülenecek sohbet yok. Bir temsilciyle sohbet etmeye başlayabilirsiniz.",
  "de": "Es gibt keinen anzuzeigenden Chat. Sie können mit einem Agenten chatten."
}

export const CHAT_LOADING_TEXT= {
  "en": "Starting a chat session ...",
  "ja": "チャットセッションを開始中...",
  "zh": "正在开始聊天会话...",
  "zh-TW": "正在開始聊天會話...",
  "ko": "채팅 세션 시작 중...",
  "fr": "Démarrage d'une session de chat...",
  "es": "Comenzando una sesión de chat...",
  "pt": "Iniciando uma sessão de bate-papo...",
  "ru": "Начало сеанса чата...",
  "tr": "Sohbet oturumu başlatılıyor...",
  "de": "Eine Chatsitzung starten..."
}

export const CUSTOMER_DISPLAY_NAME= {
  "en": "You",
  "ja": "あなた",
  "zh": "你",
  "zh-TW": "你",
  "ko": "너",
  "fr": "Tu",
  "es": "Tú",
  "pt": "Você",
  "ru": "Ты",
  "tr": "Sen",
  "de": "Du"
}

export const AGENT_DISPLAY_NAME= {
  "en": "Agent",
  "ja": "エージェント",
  "zh": "代理人",
  "zh-TW": "代理",
  "ko": "에이전트",
  "fr": "Agent",
  "es": "Agente",
  "pt": "Agente",
  "ru": "Агент",
  "tr": "Ajan",
  "de": "Agent"
}

export const SYSTEM_DISPLAY_NAME= {
  "en": "System Message",
  "ja": "システムメッセージ",
  "zh": "系统消息",
  "zh-TW": "系統消息",
  "ko": "시스템 메시지",
  "fr": "Message système",
  "es": "Mensaje del sistema",
  "pt": "Mensagem do sistema",
  "ru": "Системное сообщение",
  "tr": "Sistem İletisi",
  "de": "System-Meldung"
}

export const SEND_MESSAGE_FAILURE_ERROR= {
  "en": "Failed to send a message",
  "ja": "メッセージの送信に失敗しました。",
  "zh": "发送消息失败",
  "zh-TW": "發送消息失敗",
  "ko": "메시지를 보내지 못했습니다.",
  "fr": "Impossible d'envoyer un message",
  "es": "No se pudo enviar un mensaje",
  "pt": "Falha ao enviar uma mensagem",
  "ru": "Не удалось отправить сообщение",
  "tr": "Mesaj gönderilemedi",
  "de": "Nachricht konnte nicht gesendet werden"
}

export const CUSTOMER_INPUT_PLACEHOLDER= {
  "en": "Type a message here and press Enter.",
  "ja": "ここにメッセージを入力し、Enter キーを押してください。",
  "zh": "在此处键入一条消息，然后按 Enter 键。",
  "zh-TW": "在此處鍵入留言，然後按 Enter 鍵。",
  "ko": "여기에 메시지를 입력하고 Enter 키를 누릅니다.",
  "fr": "Saisissez un message ici et appuyez sur Entrée.",
  "es": "Escribe un mensaje aquí y presiona Entrar.",
  "pt": "Digite uma mensagem aqui e pressione Enter.",
  "ru": "Введите здесь сообщение и нажмите Enter.",
  "tr": "Buraya bir mesaj yazın ve Enter tuşuna basın.",
  "de": "Geben Sie hier eine Nachricht ein und drücken Sie Enter."
}

export const SHOW_ORIGINAL_CHECKBOX_LABEL= {
  "en": "Show agent's messages without translation",
  "ja": "エージェントのメッセージを翻訳なしで表示する",
  "zh": "在不翻译的情况下显示座席的消息",
  "zh-TW": "顯示座席的消息而不翻譯",
  "ko": "번역하지 않고 상담원의 메시지 표시",
  "fr": "Afficher les messages de l'agent sans traduction",
  "es": "Mostrar los mensajes del agente sin traducción",
  "pt": "Mostrar mensagens do agente sem tradução",
  "ru": "Показывать сообщения агента без перевода",
  "tr": "Aracının mesajlarını çeviri olmadan göster",
  "de": "Nachrichten des Agenten ohne Übersetzung anzeigen"
}

export const SHOW_ORIGINAL_CHECKBOX_DESCRIPTION= {
  "en": "Click this checkbox to see agent's messages without translation.",
  "ja": "このチェックボックスをクリックすると、エージェントのメッセージが翻訳なしで表示されます。",
  "zh": "点击此复选框可查看不带翻译的座席消息。",
  "zh-TW": "單擊此複選框可查看不翻譯的座席消息。",
  "ko": "번역하지 않고 상담원의 메시지를 보려면 이 확인란을 클릭합니다.",
  "fr": "Cochez cette case pour voir les messages de l'agent sans traduction.",
  "es": "Haga clic en esta casilla de verificación para ver los mensajes del agente sin traducción.",
  "pt": "Clique nessa caixa de seleção para ver as mensagens do agente sem tradução.",
  "ru": "Установите этот чекбокс, чтобы увидеть сообщения агента без перевода.",
  "tr": "Aracının çevirisi olmayan iletilerini görmek için bu onay kutusunu tıklatın.",
  "de": "Klicken Sie dieses Kästchen an, um die Nachrichten des Agenten ohne Übersetzung anzuzeigen."
}

export const SHOW_TRANSLATION_CHECKBOX_LABEL= {
  "en": "Show what you typed in English",
  "ja": "入力した内容を英語で表示する",
  "zh": "显示你用英文键入的内容",
  "zh-TW": "顯示您用英語輸入的內容",
  "ko": "입력한 내용을 영어로 표시",
  "fr": "Afficher ce que vous avez tapé en anglais",
  "es": "Mostrar lo que escribiste en inglés",
  "pt": "Mostrar o que você digitou em inglês",
  "ru": "Покажите, что вы набрали на английском",
  "tr": "İngilizce yazdıklarını göster",
  "de": "Zeigen Sie, was Sie auf Englisch eingegeben haben"
}

export const SHOW_TRANSLATION_CHECKBOX_DESCRIPTION= {
  "en": "Click this checkbox to see how your messages were translated into English.",
  "ja": "このチェックボックスをクリックすると、メッセージがどのように英語に翻訳されたかを確認できます。",
  "zh": "点击此复选框可查看您的邮件是如何翻译成英文的。",
  "zh-TW": "點擊此複選框可查看您的郵件是如何翻譯成英語的。",
  "ko": "메시지가 영어로 번역된 방식을 보려면 이 확인란을 클릭합니다.",
  "fr": "Cochez cette case pour voir comment vos messages ont été traduits en anglais.",
  "es": "Haga clic en esta casilla de verificación para ver cómo se tradujeron sus mensajes al inglés.",
  "pt": "Clique nessa caixa de seleção para ver como suas mensagens foram traduzidas para o inglês.",
  "ru": "Установите этот флажок, чтобы увидеть, как ваши сообщения были переведены на английский язык.",
  "tr": "Mesajlarınızın İngilizce'ye nasıl çevrildiğini görmek için bu onay kutusunu tıklatın.",
  "de": "Klicken Sie auf dieses Kästchen, um zu sehen, wie Ihre Nachrichten ins Englische übersetzt wurden."
}

export const END_CHAT_BUTTON= {
  "en": "Close Chat",
  "ja": "チャットを閉じる",
  "zh": "关闭聊天",
  "zh-TW": "關閉聊天",
  "ko": "채팅 닫기",
  "fr": "Fermer le chat",
  "es": "Cerrar chat",
  "pt": "Fechar bate-papo",
  "ru": "Закрыть чат",
  "tr": "Sohbet Kapat",
  "de": "Chat schließen"
}

export const NO_TRANSLATION_AVAILBLE_MESSAGE= {
  "en": "This message was not translated.",
  "ja": "このメッセージは翻訳されませんでした。",
  "zh": "此消息未翻译。",
  "zh-TW": "此消息未被翻譯。",
  "ko": "이 메시지는 번역되지 않았습니다.",
  "fr": "Ce message n'a pas été traduit.",
  "es": "Este mensaje no se ha traducido.",
  "pt": "Essa mensagem não foi traduzida.",
  "ru": "Это сообщение не было переведено.",
  "tr": "Bu mesaj tercüme edilmedi.",
  "de": "Diese Nachricht wurde nicht übersetzt."
}

export const AGENT_JOINED_EVENT_MESSAGE= {
  "en": "Agent (xxxxxxxx) has joined to the chat.",
  "ja": "エージェント (xxxxxxxx) がチャットに参加しました。",
  "zh": "座席 (xxxxxxxx) 已加入聊天。",
  "zh-TW": "代理 (XXXXXXXX) 已加入聊天。",
  "ko": "상담원 (xxxxxxxx) 이 채팅에 참여했습니다.",
  "fr": "L'agent (xxxxxxxx) a rejoint le chat.",
  "es": "El agente (xxxxxxxx) se ha unido al chat.",
  "pt": "O agente (xxxxxxxx) entrou no chat.",
  "ru": "Агент (xxxxxxxx) присоединился к чату.",
  "tr": "Ajan (xxxxxxxx) sohbete katıldı.",
  "de": "Der Agent (xxxxxxxx) ist dem Chat beigetreten."
}

export const CUSTOMER_JOINED_EVENT_MESSAGE= {
  "en": "You have conneted to the chat system.",
  "ja": "チャットシステムに接続しました。",
  "zh": "您已连接到聊天系统。",
  "zh-TW": "你已經連接到聊天系統。",
  "ko": "채팅 시스템에 연결되었습니다.",
  "fr": "Vous êtes connecté au système de discussion.",
  "es": "Te has conectado al sistema de chat.",
  "pt": "Você se conectou ao sistema de bate-papo.",
  "ru": "Вы подключились к системе чата.",
  "tr": "Sohbet sistemine bağladınız.",
  "de": "Sie haben eine Verbindung zum Chat-System hergestellt."
}

export const AGENT_LEFT_EVENT_MESSAGE= {
  "en": "Agent (xxxxxxxx) has lefted the chat.",
  "ja": "エージェント (xxxxxxxx) がチャットから退出しました。",
  "zh": "座席 (xxxxxxxx) 已离开聊天室。",
  "zh-TW": "代理 (XXXXXXXX) 已經轉移了聊天。",
  "ko": "에이전트 (xxxxxxxx) 가 채팅을 떠났습니다.",
  "fr": "L'agent (xxxxxxxx) a quitté le chat.",
  "es": "El agente (xxxxxxxx) ha abandonado el chat.",
  "pt": "Agent (xxxxxxxx) deixou o chat.",
  "ru": "Агент (xxxxxxxx) покинул чат.",
  "tr": "Ajan (xxxxxxxx) sohbeti solladı.",
  "de": "Der Agent (xxxxxxxx) hat den Chat verlassen."
}

export const CUSTOMER_LEFT_EVENT_MESSAGE= {
  "en": "You have lefted the chat.",
  "ja": "チャットから退出しました。",
  "zh": "你已经离开了聊天室。",
  "zh-TW": "你已經轉移了聊天。",
  "ko": "채팅에서 나갔습니다.",
  "fr": "Vous avez quitté le chat.",
  "es": "Abandonaste el chat.",
  "pt": "Você saiu do chat.",
  "ru": "Вы покинули чат.",
  "tr": "Sohbeti sen terlettin.",
  "de": "Sie haben den Chat verlassen."
}

export const CHAT_ENDED_EVENT_MESSAGE= {
  "en": "The chat has ended.",
  "ja": "チャットは終了しました。",
  "zh": "聊天已结束。",
  "zh-TW": "聊天已經結束。",
  "ko": "채팅이 종료되었습니다.",
  "fr": "La discussion est terminée.",
  "es": "La charla ha terminado.",
  "pt": "O bate-papo terminou.",
  "ru": "Чат закончился.",
  "tr": "Sohbet sona erdi.",
  "de": "Der Chat ist beendet."
}

export const SEND_WITHOUT_TRANSLATE_BUTTON= {
  "en": "Send without Translation",
  "ja": "翻訳なしで送信",
  "zh": "发送但不翻译",
  "zh-TW": "發送而不翻譯",
  "ko": "번역 없이 보내기",
  "fr": "Envoyer sans traduction",
  "es": "Enviar sin traducción",
  "pt": "Enviar sem tradução",
  "ru": "Отправить без перевода",
  "tr": "Çeviri olmadan gönder",
  "de": "Ohne Übersetzung senden"
}

