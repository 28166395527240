import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CUSTOMER_DISPLAY_NAME,
  AGENT_DISPLAY_NAME,
  SYSTEM_DISPLAY_NAME,
  SEND_MESSAGE_FAILURE_ERROR,
  NO_TRANSLATION_AVAILBLE_MESSAGE
} from '../../utilities/constants';

const useStyles = makeStyles(theme => ({
  message: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "98%",
    marginLeft: "10px",
  },
  customerBubbleContainer: {
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "10px",
  },  
  otherBubbleContainer: {
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex",
    marginLeft: "10px",
  },
  customerBubble: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    marginTop: "5px",
    marginBottom: "5px",
    margin: "1px 0px",
    background: "#ec7211",
    padding: "10px 15px",
    maxWidth: "80%",
    color: "white",
  },
  customerBubblePending: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    marginTop: "5px",
    marginBottom: "5px",
    margin: "1px 0px",
    background: "#f3aa70",
    padding: "10px 15px",
    maxWidth: "80%",
    color: "white",
  },
  otherBubble: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    marginTop: "5px",
    marginBottom: "5px",
    margin: "1px 0px",
    background: "#f4f4f8",
    padding: "10px 15px",
    maxWidth: "80%",
  },
  customerName: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "10px",
  },
  otherName: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "flex",
    marginLeft: "10px"
  },
  failureContainer: {
    fontSize: "13px",
    color: "red",
    lineHeight: "350%",
    marginRight: "3px"
  },
  chatText: {
    whiteSpace: "pre-wrap"
  },
  noTranslationText: {
    whiteSpace: "pre-wrap",
    fontSize: "11px"
  }
}));

const ChatMessage = ({
  chatContent,
  agent,
  languageCode,
  showOriginal,
  showTranslated 
}) => {
  const classes = useStyles();
  
  return (
    <div>
      { chatContent.IsBeginning ? 
        <div
          className={chatContent.ParticipantRole === "CUSTOMER" ? classes.customerName : classes.otherName}
        >
          {chatContent.ParticipantRole === "CUSTOMER" ? CUSTOMER_DISPLAY_NAME[languageCode] : 
            chatContent.ParticipantRole === "AGENT" ? AGENT_DISPLAY_NAME[languageCode] + " (" + agent + ")" : SYSTEM_DISPLAY_NAME[languageCode]
          }
        </div> 
        :
        ""
      }
      <div 
        className={chatContent.ParticipantRole === "CUSTOMER" ? classes.customerBubbleContainer : classes.otherBubbleContainer}
      >
        <span className={classes.failureContainer}>{chatContent.Status === "FAILURE" ? SEND_MESSAGE_FAILURE_ERROR[languageCode] : ""}</span>
        {
          chatContent.Status === "PENDING" ?
          <div className={classes.customerBubblePending}>
            <div className={classes.chatText}>{chatContent.Content}</div>
          </div> :
          <div className={chatContent.ParticipantRole === "CUSTOMER" ? classes.customerBubble : classes.otherBubble}>
            {chatContent.ParticipantRole === "AGENT"  ? 
              <div>
                <div className={classes.chatText}>
                  {showOriginal && chatContent.OriginalContent === chatContent.Content ? <i className={classes.noTranslationText}>{NO_TRANSLATION_AVAILBLE_MESSAGE[languageCode]}</i> : "" }
                </div>
                <div className={classes.chatText}>
                  {showOriginal ? chatContent.OriginalContent : chatContent.Content}
                </div>
              </div> :
              chatContent.ParticipantRole === "CUSTOMER" ? 
                <div>
                  <div className={classes.chatText}>
                    {showTranslated && chatContent.TranslatedContent === chatContent.Content ? <i className={classes.noTranslationText}>{NO_TRANSLATION_AVAILBLE_MESSAGE[languageCode]}</i> : "" }
                  </div>
                  <div className={classes.chatText}>
                    {showTranslated ? chatContent.TranslatedContent : chatContent.Content}
                  </div>
                </div> 
                :
                <div className={classes.chatText}>{chatContent.Content}</div>
            }
          </div>
        }        
      </div>
    </div>
  )
}

export default ChatMessage;
