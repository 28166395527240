export const getChatContent = (chatContentList, currentChatContent) => {
  let chatContent = Object.assign({}, currentChatContent);
  let lastParticipant = ""

  if (chatContentList.length == 0) {
    chatContent.IsBeginning = true;
    chatContent.Status = "COMPLETED";
    return chatContent;
  } else {
    chatContentList.forEach((item)=>{
      if (item.EventType == "MESSAGE") {
        lastParticipant = item.ParticipantRole;
      }
    });

    if (lastParticipant !== chatContent.ParticipantRole) {
      chatContent.IsBeginning = true;
    } else {
      chatContent.IsBeginning = false;
    }

    if (chatContent.ParticipantRole === "CUSTOMER") {
      chatContent.Status = "PENDING";
    } else {
      chatContent.Status = "COMPLETED";
    }

    return chatContent;
  }
}

export const generateEventId = () => {
  let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
  for (let i = 0, len = chars.length; i < len; i++) {
    switch(chars[i]) {
      case "x":
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case "y":
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return chars.join("");
}

export const generateTimestamp = () => {
  //ISO 8601 format (YYYY-MM-DDThh:mm:ss.sss)
  const now = new Date();
  return now.toISOString();
}

export const buildCustomerChatContentObject = (content) => {
  const chatContentObject = {
    "EventId": generateEventId(),
    "ParticipantRole": "CUSTOMER",
    "EventType": "MESSAGE",
    "Timestamp": generateTimestamp(),
    "Content": content
  };
  return chatContentObject;
}


