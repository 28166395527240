"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authTheme = exports.theme = exports.getTheme = exports.getAuthTheme = void 0;
const color_1 = require("../config/color");
const typography_1 = require("../config/typography");
const spacing_1 = require("../config/spacing");
const cssCommon_1 = require("../config/cssCommon");
/**
 * This theme can be used by an amplify auth component on a login page.
 */
const getAuthTheme = (fontFamily) => ({
    a: { color: color_1.COLOR_PALETTE['orange'].color },
    button: { backgroundColor: color_1.COLOR_PALETTE['orange'].color },
    formSection: {
        fontFamily: fontFamily || typography_1.DEFAULT_FONT_FAMILY,
        backgroundColor: color_1.COLOR_PALETTE.white.color,
        borderRadius: '10px',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.25)',
    },
    navButton: { backgroundColor: color_1.COLOR_PALETTE['orange'].color },
    navItem: { fontWeight: 'bold' },
    navRight: {
        background: 'url(/img/logo-black.png) no-repeat',
        backgroundSize: '48px',
    },
    sectionHeader: { content: "url('/img/logo-black.png')" },
});
exports.getAuthTheme = getAuthTheme;
const getTheme = (fontFamily) => ({
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
    },
    direction: 'ltr',
    mixins: {
        toolbar: {
            '@media (min-width:0px) and (orientation: landscape)': {
                minHeight: 48,
            },
            '@media (min-width:600px)': { minHeight: 64 },
            minHeight: 56,
        },
    },
    overrides: {
        MuiAlert: {
            root: {
                alignItems: 'center',
            },
            outlinedSuccess: {
                backgroundColor: color_1.COLOR_PALETTE['green-light'].color,
            },
            outlinedError: {
                backgroundColor: color_1.COLOR_PALETTE['red-light'].color,
            },
            outlinedInfo: {
                backgroundColor: color_1.COLOR_PALETTE['blue-light'].color,
            },
            outlinedWarning: {
                backgroundColor: color_1.COLOR_PALETTE['red-light'].color,
            },
            filledSuccess: {
                backgroundColor: color_1.COLOR_PALETTE['green-dark'].color,
            },
            filledError: {
                backgroundColor: color_1.COLOR_PALETTE['red-dark'].color,
            },
            filledInfo: {
                backgroundColor: color_1.COLOR_PALETTE['blue-dark'].color,
            },
            filledWarning: {
                backgroundColor: color_1.COLOR_PALETTE['red-dark'].color,
            },
        },
        MuiAutocomplete: {
            groupLabel: {
                lineHeight: cssCommon_1.DEFAULT_LINE_HEIGHT,
                paddingTop: '6px',
                paddingBottom: '6px',
            },
            inputRoot: {
                "&[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
            option: {
                '&[aria-selected="true"]': {
                    backgroundColor: color_1.COLOR_PALETTE['blue-light'].color,
                    border: `1px solid ${color_1.COLOR_PALETTE['blue'].color}`,
                },
            },
        },
        MuiCheckbox: {
            root: {
                padding: 0,
            },
        },
        MuiAccordionSummary: {
            root: {
                padding: '4px 0',
                fontWeight: typography_1.DEFAULT_FONT_WEIGHT_HEAVE,
                fontSize: typography_1.DEFAULT_FONT_SIZE,
                minHeight: 0,
                margin: 0,
                color: color_1.COLOR_PALETTE['grey-900'].color,
                '&$expanded': {
                    padding: '4px 0',
                    minHeight: 0,
                    margin: 0,
                },
            },
            content: {
                margin: 0,
                '&$expanded': {
                    margin: 0,
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: '10px 0',
            },
        },
        MuiRadio: {
            root: {
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&.MuiRadio-colorSecondary.Mui-checked': {
                    color: color_1.COLOR_PALETTE['grey-900'].color,
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        // @ts-ignore
        MuiPickersDay: {
            daySelected: {
                backgroundColor: color_1.COLOR_PALETTE['orange'].color,
                '&:hover': {
                    backgroundColor: color_1.COLOR_PALETTE['orange-dark'].color,
                    color: color_1.COLOR_PALETTE.white.color,
                },
            },
        },
        MuiSvgIcon: {
            fontSizeSmall: {
                width: '16px',
                height: '16px',
                marginTop: '2px',
            },
            colorSecondary: {
                color: color_1.COLOR_PALETTE.white.color,
            },
        },
        MuiSwitch: {
            root: {
                width: '24px',
                height: '16px',
                padding: '0px',
                fontSize: typography_1.DEFAULT_FONT_SIZE,
            },
            switchBase: {
                color: color_1.COLOR_PALETTE['grey-50'].color,
                padding: '0px',
                '&.Mui-checked': {
                    transform: 'translateX(8px)',
                },
            },
            thumb: {
                width: '12px',
                height: '12px',
                marginTop: '2px',
                marginLeft: '2px',
            },
            colorSecondary: {
                '&$checked': {
                    color: color_1.COLOR_PALETTE.white.color,
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
                '&$checked + $track': {
                    backgroundColor: color_1.COLOR_PALETTE['blue-dark'].color,
                },
            },
        },
        MuiTreeView: {
            root: {
                transition: 'none',
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: '2px',
            },
        },
        MuiLink: {
            root: {
                color: color_1.COLOR_PALETTE['blue-dark'].color,
            },
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        MuiSelect: {
            root: {
                padding: '4px 10px',
                textOverflow: 'ellipsis',
            },
        },
        MuiMenuItem: {
            root: {
                border: '1px solid transparent',
                display: 'flex',
                alignItems: 'flex-start',
                '&:hover': {
                    backgroundColor: color_1.COLOR_PALETTE['grey-100'].color,
                    border: `1px solid ${color_1.COLOR_PALETTE['grey-400'].color}`,
                },
                '&.Mui-selected, &.Mui-selected:hover': {
                    border: `1px solid ${color_1.COLOR_PALETTE['blue'].color}`,
                    backgroundColor: color_1.COLOR_PALETTE['blue-light'].color,
                },
            },
        },
        MuiButton: {
            root: {
                color: color_1.COLOR_PALETTE.white.color,
                textDecoration: 'none',
                border: '1px solid',
                borderRadius: '2px',
                transition: 'none',
                padding: '4px 20px',
                textTransform: 'none',
                '&[disabled]': {
                    padding: '4px 20px',
                    border: '1px solid',
                    borderRadius: '2px',
                    borderColor: color_1.COLOR_PALETTE['grey-300'].color,
                    cursor: 'initial',
                    color: color_1.COLOR_PALETTE['grey-500'],
                    backgroundColor: 'transparent',
                },
                '& .MuiIconButton-root:hover': {
                    color: color_1.COLOR_PALETTE.white.color,
                },
                '&.Mui-focusVisible': {
                    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
                },
            },
            containedPrimary: {
                padding: '4px 20px',
                backgroundColor: color_1.COLOR_PALETTE['orange'].color,
                borderColor: color_1.COLOR_PALETTE['orange'].color,
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                    borderColor: color_1.COLOR_PALETTE['orange-dark'].color,
                    backgroundColor: color_1.COLOR_PALETTE['orange-dark'].color,
                    textDecoration: 'none',
                },
            },
            text: {
                padding: '4px 20px',
                background: 'transparent',
                color: color_1.COLOR_PALETTE['grey-800'].color,
                textDecoration: 'none',
                '&:hover': {
                    color: color_1.COLOR_PALETTE['grey-900'].color,
                    background: 'transparent',
                },
                '&[href]': {
                    borderColor: 'transparent',
                },
            },
            label: {
                lineHeight: '20px',
                fontWeight: 'bold',
                letterSpacing: '.25px',
            },
        },
        MuiIconButton: {
            root: {
                backgroundColor: 'transparent',
                color: 'currentColor',
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'currentColor',
                },
                '&.Mui-focusVisible': {
                    borderColor: color_1.COLOR_PALETTE['blue'].color,
                    borderWidth: '1px',
                    outline: '2px dotted transparent',
                    boxShadow: `0 0 0 2px ${color_1.COLOR_PALETTE['blue'].color}`,
                    borderRadius: '2px',
                },
            },
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                transition: 'none',
            },
            root: {
                paddingTop: 4,
                paddingBottom: 4,
            },
        },
        MuiTabs: {
            root: {
                minHeight: 'auto',
            },
            scroller: {
                borderBottom: `1px solid ${color_1.COLOR_PALETTE['grey-400'].color}`,
                padding: '.75rem 0',
            },
        },
        MuiTab: {
            root: {
                minHeight: 'auto',
                minWidth: 'auto',
                lineHeight: 'none',
                padding: '0 1rem',
                textTransform: 'none',
                '@media (min-width: 600px)': {
                    minWidth: 'auto',
                },
            },
            wrapper: {
                fontWeight: typography_1.DEFAULT_FONT_WEIGHT_HEAVE,
                fontSize: typography_1.DEFAULT_FONT_SIZE,
            },
            textColorInherit: {
                '&.Mui-selected': {
                    color: color_1.COLOR_PALETTE['orange-dark'].color,
                },
                '&:hover': {
                    color: color_1.COLOR_PALETTE['orange-dark'].color,
                    opacity: 1,
                },
            },
        },
        MuiTextField: {},
        MuiInput: {
            underline: {
                '&:hover:not(.Mui-disabled)::before': {
                    borderBottom: '0',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: color_1.COLOR_PALETTE.white.color,
                borderRadius: '2px',
                '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                    borderColor: color_1.COLOR_PALETTE['blue'].color,
                    borderWidth: '1px',
                    outline: '2px dotted transparent',
                    boxShadow: `0 0 0 1px ${color_1.COLOR_PALETTE['blue'].color}`,
                },
                '&:hover:not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
                    borderColor: color_1.COLOR_PALETTE['blue'].color,
                    borderWidth: '1px',
                },
            },
            input: {
                fontSize: typography_1.DEFAULT_FONT_SIZE,
                padding: '4px 10px',
            },
            adornedStart: {
                paddingLeft: '5px',
                paddingTop: '1px',
            },
            adornedEnd: {
                paddingRight: '0px',
            },
            inputMarginDense: {
                paddingTop: '3px',
                paddingBottom: '4px',
            },
        },
        MuiInputBase: {
            root: {
                fontSize: typography_1.DEFAULT_FONT_SIZE,
                lineHeight: cssCommon_1.DEFAULT_LINE_HEIGHT,
            },
            inputTypeSearch: {
                '&::-webkit-search-cancel-button': {
                    '-webkit-appearance': 'none',
                },
            },
            input: {
                height: cssCommon_1.DEFAULT_LINE_HEIGHT,
                '&::placeholder': {
                    fontStyle: 'italic',
                },
            },
        },
        MuiTableCell: {
            root: {
                fontSize: '.75rem',
                borderBottom: 'none',
                fontWeight: 'normal',
            },
            head: {
                fontWeight: typography_1.DEFAULT_FONT_WEIGHT_HEAVE,
                lineHeight: 'normal',
                userSelect: 'none',
            },
            sizeSmall: {
                padding: '9px 12px',
            },
        },
        MuiTableRow: {
            root: {
                '&.Mui-selected, &.Mui-selected:hover': {
                    border: `1px solid ${color_1.COLOR_PALETTE['blue'].color}`,
                    backgroundColor: color_1.COLOR_PALETTE['blue-light'].color,
                },
                border: '1px solid transparent',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                width: 'auto !important',
            },
            head: {
                backgroundColor: color_1.COLOR_PALETTE['grey-100'].color,
            },
        },
        MuiChip: {
            root: {
                fontSize: '12px',
                borderRadius: '16px',
                height: 'auto',
                lineHeight: '20px',
            },
            label: {
                paddingLeft: '7.5px',
                paddingRight: '7.5px',
            },
        },
        MuiFormControl: {
            root: {
                width: '100%',
            },
            marginNormal: {
                marginTop: '0',
                marginBottom: '0',
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: 500,
                lineHeight: 1.4,
            },
            animated: {
                transition: 'none',
            },
            shrink: {
                transform: 'none',
            },
            formControl: {
                transform: 'none',
                position: 'static',
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '12px',
                lineHeight: 1.5,
            },
        },
    },
    palette: {
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            hover: 'rgba(0, 0, 0, 0.08)',
            hoverOpacity: 0.08,
            selected: 'rgba(0, 0, 0, 0.14)',
        },
        background: { paper: color_1.COLOR_PALETTE.white.color, default: color_1.COLOR_PALETTE['grey-100'].color },
        common: { black: '#000', white: color_1.COLOR_PALETTE.white.color },
        contrastThreshold: 3,
        divider: color_1.COLOR_PALETTE['grey-200'].color,
        grey: {
            '50': color_1.COLOR_PALETTE['grey-500'].color,
            '100': color_1.COLOR_PALETTE['grey-100'].color,
            '200': color_1.COLOR_PALETTE['grey-200'].color,
            '300': color_1.COLOR_PALETTE['grey-300'].color,
            '400': color_1.COLOR_PALETTE['grey-400'].color,
            '500': color_1.COLOR_PALETTE['grey-500'].color,
            '600': color_1.COLOR_PALETTE['grey-600'].color,
            '700': color_1.COLOR_PALETTE['grey-700'].color,
            '800': color_1.COLOR_PALETTE['grey-800'].color,
            '900': color_1.COLOR_PALETTE['grey-900'].color,
        },
        primary: {
            contrastText: color_1.COLOR_PALETTE.white.color,
            main: color_1.COLOR_PALETTE['charcoal'].color,
            light: color_1.COLOR_PALETTE['charcoal-light'].color,
            dark: color_1.COLOR_PALETTE['charcoal-dark'].color,
        },
        secondary: {
            contrastText: color_1.COLOR_PALETTE.white.color,
            dark: color_1.COLOR_PALETTE['orange-dark'].color,
            light: color_1.COLOR_PALETTE['orange-light'].color,
            main: color_1.COLOR_PALETTE['orange'].color,
        },
        text: {
            disabled: color_1.COLOR_PALETTE['grey-600'].color,
            hint: color_1.COLOR_PALETTE['grey-600'].color,
            primary: color_1.COLOR_PALETTE['grey-900'].color,
            secondary: color_1.COLOR_PALETTE['grey-800'].color,
        },
        success: {
            contrastText: color_1.COLOR_PALETTE.white.color,
            main: color_1.COLOR_PALETTE.green.color,
            dark: color_1.COLOR_PALETTE['green-dark'].color,
            light: color_1.COLOR_PALETTE['green-light'].color,
        },
        error: {
            contrastText: color_1.COLOR_PALETTE.white.color,
            main: color_1.COLOR_PALETTE.red.color,
            dark: color_1.COLOR_PALETTE['red-dark'].color,
            light: color_1.COLOR_PALETTE['red-light'].color,
        },
        warning: {
            contrastText: color_1.COLOR_PALETTE.white.color,
            main: color_1.COLOR_PALETTE.red.color,
            dark: color_1.COLOR_PALETTE['red-dark'].color,
            light: color_1.COLOR_PALETTE['red-light'].color,
        },
        info: {
            contrastText: color_1.COLOR_PALETTE.white.color,
            main: color_1.COLOR_PALETTE.blue.color,
            dark: color_1.COLOR_PALETTE['blue-dark'].color,
            light: color_1.COLOR_PALETTE['blue-light'].color,
        },
        tonalOffset: 0.2,
        type: 'light',
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiListItem: {
            disableGutters: true,
        },
    },
    shadows: [
        'none',
        '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
        '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
        '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
        '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
        '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
        '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
        '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
        '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
        '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
        '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
        '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
        '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
        '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
        '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
        '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
        '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
        '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
        '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
        '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    ],
    shape: { borderRadius: cssCommon_1.DEFAULT_RADIUS },
    spacing: spacing_1.DEFAULT_SPACING,
    transitions: {
        duration: {
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195,
            short: 250,
            shorter: 200,
            shortest: 150,
            standard: 300,
        },
        easing: {
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
    },
    typography: {
        h1: typography_1.TYPOGRAPHY.h1,
        h2: typography_1.TYPOGRAPHY.h2,
        h3: typography_1.TYPOGRAPHY.h3,
        h4: typography_1.TYPOGRAPHY.h4,
        h5: typography_1.TYPOGRAPHY.h5,
        body1: typography_1.TYPOGRAPHY.p,
        body2: typography_1.TYPOGRAPHY.small,
        subtitle1: typography_1.TYPOGRAPHY.small,
        fontFamily: fontFamily || typography_1.DEFAULT_FONT_FAMILY,
        fontSize: typography_1.DEFAULT_FONT_SIZE_NUMBER,
        fontWeightLight: typography_1.DEFAULT_FONT_WEIGHT_LIGHT,
        fontWeightBold: typography_1.DEFAULT_FONT_WEIGHT_HEAVE,
        fontWeightRegular: typography_1.DEFAULT_FONT_WEIGHT_REGULAR,
        fontWeightMedium: typography_1.DEFAULT_FONT_WEIGHT_REGULAR,
    },
    zIndex: {
        mobileStepper: 1000,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
});
exports.getTheme = getTheme;
const theme = exports.getTheme();
exports.theme = theme;
const authTheme = exports.getAuthTheme();
exports.authTheme = authTheme;
