import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AGENT_JOINED_EVENT_MESSAGE,
  CUSTOMER_JOINED_EVENT_MESSAGE,
  AGENT_LEFT_EVENT_MESSAGE,
  CUSTOMER_LEFT_EVENT_MESSAGE,
  CHAT_ENDED_EVENT_MESSAGE
} from '../../utilities/constants';

const useStyles = makeStyles(theme => ({
  message: {
    textAlign: "center",
    fontSize: "14px",
    color: "#808080",
    marginTop: "15px",
    marginBottom: "15px",
  }
}));

const buildEventMessage = (chatContent, agent, languageCode) => {
    switch (chatContent.EventType) {
    case "JOINED":
      if (chatContent.ParticipantRole === "AGENT") {
        return AGENT_JOINED_EVENT_MESSAGE[languageCode].replace("xxxxxxxx", agent);
      } else {
        return CUSTOMER_JOINED_EVENT_MESSAGE[languageCode];
      }

    case "LEFT":
      if (chatContent.ParticipantRole === "AGENT") {
        return AGENT_LEFT_EVENT_MESSAGE[languageCode].replace("xxxxxxxx", agent);
      } else {
        return CUSTOMER_LEFT_EVENT_MESSAGE[languageCode];
      }

    case "ENDED":
      return CHAT_ENDED_EVENT_MESSAGE[languageCode];
    
    default:
      console.log("Branched to default.")
      return "";
  }
}

const ChatEvent = ({ 
  chatContent,
  agent,
  languageCode
}) => {

  const classes = useStyles();
  const eventMessage = buildEventMessage(chatContent, agent, languageCode);

  return (
    <div className={classes.message}><i>{ eventMessage }</i></div>
  );
}

export default ChatEvent;
