import React from 'react';
import axios from 'axios';
import Container from 'aws-northstar/layouts/Container';
import TranscriptUtility from '../TranscriptUtility/';
import TranscriptWindow from '../TranscriptWindow';

class TranscriptAppContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      contactId: "",
      isContactIdSet: false,
      chatContentList: []
    }

    this.handleGetChatTranscriptClick = this.handleGetChatTranscriptClick.bind(this);
    this.handleContactIdChange = this.handleContactIdChange.bind(this);
  }

  getChatTranscript() {
    axios.get(
      process.env.REACT_APP_GET_CHAT_TRANSCRIPT_API + 
      "?ContactId=" + this.state.contactId
    ).then((response) => {
      this.setState({
        chatContentList: response.data,
        isLoading: false,
        isContactIdSet: true
      })
    })
  }

  handleContactIdChange(value) {
    console.log(value)
    this.setState({
      contactId: value
    })
  }

  async handleGetChatTranscriptClick() {
    this.setState({
      isLoading: true
    });
    this.getChatTranscript();
  }

  render() {
    return (
      <Container>
        <TranscriptUtility
          isLoading={this.state.isLoading}
          contactId={this.state.contactId}
          handleContactIdChange={this.handleContactIdChange}
          handleGetChatTranscriptClick={this.handleGetChatTranscriptClick}
        />
        <TranscriptWindow 
          isLoading={this.state.isLoading}
          isContactIdSet={this.state.isContactIdSet}
          contactId={this.state.contactId}
          chatContentList={this.state.chatContentList}
        />
      </Container>
    )
  }
}

export default TranscriptAppContainer