import React from 'react';
import Header from 'aws-northstar/components/Header';

const AppHeader = () => {
  return(
    <div>
      <Header title='Realtime Chat Translation Demo' />
    </div>
  )
}

export default AppHeader;