import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from 'aws-northstar/layouts/Grid';
import Form from 'aws-northstar/components/Form';
import FormSection from 'aws-northstar/components/FormSection';
import FormField from 'aws-northstar/components/FormField';
import Select from 'aws-northstar/components/Select';
import Button from 'aws-northstar/components/Button';
import { 
  START_CHAT_BUTTON, 
  END_CHAT_BUTTON,
  CHAT_SETTINGS, 
  AGENT_LABEL, 
  AGENT_HINT_TEXT, 
  LANGUAGE_LABEL,
  LANGUAGE_HINT_TEXT
} from '../../utilities/constants';

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  container: {
    width: "100%"
  },
  selector: {
    width: "30%",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  selectorChild: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    
  }
}));

const agentOptions = [
  { label: "Agent 1", value: "agent_1"},
  { label: "Agent 2", value: "agent_2"},
  { label: "Agent 3", value: "agent_3"},
  { label: "Agent 4", value: "agent_4"},
  { label: "Agent 5", value: "agent_5"},
  { label: "Default", value: "yuk"}
]

const languageOptions = [
  { label: "English", value: "en" },
  { label: "Japanese", value: "ja" },
  { label: "Chinese (Simplified)", value: "zh" },
  { label: "Chinese (Traditional)", value: "zh-TW" },
  { label: "Korean", value: "ko" },
  { label: "French", value: "fr" },
  { label: "Spanish", value: "es" },
  { label: "Portuguese", value: "pt" },
  { label: "Russian", value: "ru" },
  { label: "Turkish", value: "tr" },
  { label: "Germany", value: "de" }
]  

const ChatUtility = ({
  agent, 
  languageCode, 
  isLoading,
  contactId,
  isEnding,
  handleAgentChange, 
  handleLanguageCodeChange,
  handleStartChatClick,
  handleDisconnectChatClick
}) => {
  
  const classes = useStyles();
  return (
    <div>
      <Form
        actions = { contactId == null ?
          <Button 
            variant="primary" 
            onClick={() => handleStartChatClick()} 
            disabled={isLoading}
          >
            {START_CHAT_BUTTON[languageCode]}
          </Button>
          :
          <Button 
            variant="primary" 
            onClick={() => handleDisconnectChatClick()}
            disabled={isEnding}
          >
            {END_CHAT_BUTTON[languageCode]}
          </Button>
        }
        className={classes.form}
      >
        <FormSection header={CHAT_SETTINGS[languageCode]}>
          <Grid container spacing={0} className={classes.container}>
            <Grid item xs={5} className={classes.selector}>
              <FormField 
                label={AGENT_LABEL[languageCode]}
                hintText={AGENT_HINT_TEXT[languageCode]}
              >
                <Select 
                  controlId="agent"
                  options={agentOptions}
                  selectedOption={{value: agent}}
                  onChange={(value) => handleAgentChange(value)}
                  className={classes.selectorChild}
                  disabled={isLoading || contactId != null}
                />
              </FormField>
            </Grid>
            <Grid item xs={5} className={classes.selector}>
              <FormField 
                label={LANGUAGE_LABEL[languageCode]}
                hintText={LANGUAGE_HINT_TEXT[languageCode]}
              >
                <Select 
                  controlId="languageCode"
                  options={languageOptions}
                  selectedOption={{value: languageCode}}
                  onChange={(value) => handleLanguageCodeChange(value)}
                  className={classes.selectorChild}
                  disabled={isLoading || contactId != null}
                />
              </FormField>
            </Grid>
          </Grid>
        </FormSection>
      </Form>
    </div>
  )
}

export default ChatUtility;